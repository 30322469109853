<template>
  <div class="row">
    <div class="col-9" style="margin-left: 280px">

      <div v-if="!showQandA">
        <!-- Filter -->
        <div class="accordion" id="filterAccordion">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#filters"
                aria-expanded="false" aria-controls="filters">
                {{ $t("events.filters.title") }} ({{
                  $t("events.filters.click_to_open")
                }})
              </button>
            </h2>
            <div id="filters" class="accordion-collapse collapse show" aria-labelledby="headingOne"
              data-bs-parent="#filterAccordion">
              <div class="accordion-body">
                <Form @submit="(values) => getChecklists(values)" :validation-schema="schema" ref="form">
                  <div class="row">
                    <div class="col-md-3 col-xs-6 col-xxs-12">
                      <label for="checklistNameFilter" class="form-label">{{
                        $t("sites.name")
                      }}</label>
                      <Field type="text" name="name" class="form-control" id="checklistNameFilter"
                        placeholder="Enter name" />
                      <ErrorMessage name="name" class="error-feedback" />
                    </div>
                    <div class="col-md-3 col-xs-6 col-xxs-12">
                      <label for="checklistDescriptionFilter" class="form-label">Description</label>
                      <Field type="text" name="description" class="form-control" id="checklistDescriptionFilter"
                        placeholder="Enter Description" />
                      <ErrorMessage name="name" class="error-feedback" />
                    </div>
                  </div>
                  <div class="row m-t-md">
                    <div class="col-12" style="width:auto;">
                      <button type="submit" :disabled="loading" class="btn btn-primary" style="max-width: 150px">
                        <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                        <span>{{ $t("events.filters.search") }}</span>
                      </button>
                    </div>

                    <div class="col-12" style="width:auto;">
                      <button type="reset" :disabled="loading" class="btn btn-secondary" style="max-width: 150px;"
                        @click.prevent="clearFilter">
                        <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                        <span>{{ $t('global.clear_filter') }}</span>
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        <h1 class="table-heading">
          Checklists
        </h1>

        <div class="text-right mb-3">
          <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addChecklistModal">
            Add New Checklist
          </button>
        </div>

        <table class="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Checklist Name</th>
              <th>Description</th>
              <th>Published?</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loading">
              <td colspan="5">
                <div class="text-center">
                  <div class="spinner-border">
                    <span class="sr-only"></span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="5" v-if="!loading && checklists.length < 1">
                <div class="text-center">
                  No checklists found
                </div>
              </td>
            </tr>
            <tr v-if="!loading" v-for="checklist in checklists" @click="showChecklistQandA(checklist)"
              :key="'checklist-' + checklist.id">
              <td>{{ checklist.id }}</td>
              <td>{{ checklist.name }}</td>
              <td>{{ checklist.description }}</td>
              <td>
                <i v-if="parseInt(checklist.status) === 1" class="material-icons text-success">done</i>
                <i v-else-if="parseInt(checklist.status) === 0" class="material-icons text-danger">close</i>
                <span v-else>{{ checklist.status }}</span>
              </td>
              <td>
                <button class="btn btn-success" @click.stop="publishChecklist(checklist.id)">Publish</button>
                <button v-if="$can('publish-checklist-sites')" @click.stop="massPublishChecklist(checklist.id)" class="btn btn-info" style="margin-left: 8px;">
                  Assign to all Sites
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-else>
        <SiteChecklistQandA :site="site" :checklist="selectedChecklist"
          @hideChecklistQandA="() => { showQandA = !showQandA }" />
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="addChecklistModal" tabindex="-1" aria-labelledby="addChecklistModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addChecklistModalLabel">Add New Checklist</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="saveChecklist">
            <div class="mb-3">
              <label for="checklistName" class="form-label">Checklist Name</label>
              <input type="text" class="form-control" id="checklistName" v-model="newChecklist.name" required>
            </div>
            <div class="mb-3">
              <label for="checklistDescription" class="form-label">Description</label>
              <textarea class="form-control" id="checklistDescription" v-model="newChecklist.description"
                required></textarea>
            </div>
            <button type="submit" class="btn btn-primary me-2">Save</button>
            <button type="button" class="btn btn-secondary" @click="clearNewChecklist">Clear</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import authHeader from "../../../../services/auth-header";
import SiteChecklistQandA from "./SiteChecklistQandA.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "SiteChecklist",
  props: {
    site: Object,
  },
  components: {
    Form, Field, ErrorMessage, SiteChecklistQandA
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string(),
    });
    return {
      loading: true,
      checklists: [],
      showQandA: false,
      newChecklist: {
        name: '',
        description: ''
      }
    };
  },
  mounted() {
    this.getChecklists();
  },
  methods: {
    getChecklists() {
      this.loading = true;

      const params = {
        name: this.$refs.form?.values?.name,
        description: this.$refs.form?.values?.description,
      };

      axios
        .get(
          `https://api.varsanpr.com/api/checklist/${this.site.id}/checklists`,
          {
            params,
            headers: authHeader(),
          }
        )
        .then((response) => {
          this.checklists = response.data.checklists;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearFilter(params = {}) {
      this.$refs.form.resetForm();
    },
    showChecklistQandA(checklist) {
      this.selectedChecklist = checklist;
      this.showQandA = true;
    },
    saveChecklist() {
      const checklistData = {
        site_id: this.site.id,
        name: this.newChecklist.name,
        description: this.newChecklist.description
      };

      axios
        .put(
          `https://api.varsanpr.com/api/checklist/new/checklist`,
          checklistData,
          { headers: authHeader() }
        )
        .then((response) => {
          this.checklists.push(response.data);
          this.clearNewChecklist();
          // Close the modal
          const modal = bootstrap.Modal.getInstance(document.getElementById('addChecklistModal'));
          modal.hide();
          this.getChecklists();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearNewChecklist() {
      this.newChecklist.name = '';
      this.newChecklist.description = '';
    },
    publishChecklist(checklistId) {
      const publishData = {
        site_id: this.site.id
      };

      axios
        .patch(
          `https://api.varsanpr.com/api/checklist/${checklistId}/status`,
          publishData,
          { headers: authHeader() }
        )
        .then((response) => {
          this.getChecklists();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    massPublishChecklist(checklistId) {
      if (!confirm("Are you sure you want to assign this checklist to all sites?")) {
        return; 
      }
      const publishData = {
        client_id: this.site.client_id
      };

      console.log("publishData", publishData);

      axios.patch(
        `https://api.varsanpr.com/api/checklist/${checklistId}/status/all`,
        publishData,
        { headers: authHeader() }
      )
      .then((response) => {
        this.$success("Checklist assigned to all sites");
        this.getChecklists();
      })
      .catch((error) => {
        console.log(error);
      });
    },
  },
}
</script>

<style scoped>
.table-heading {
  margin-top: 20px;
}
</style>