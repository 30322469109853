<template>
  <div class="p-v-sm">
    <div style="justify-content: space-between; display: flex">
      <button class="btn btn-secondary m-b-sm" @click="$emit('hideChecklistQandA')">
        <i class="material-icons">arrow_back_ios_new</i> Go back
      </button>
      <div class="form-group m-b-sm">
        <label for="timeframe">Select Data Timeframe</label>
        <select v-model="selectedTimeframe" class="form-control" id="timeframe" @change="updateDateRange">
          <option value="day">Day</option>
          <option value="week">Week</option>
          <option value="month">Month</option>
          <option value="year">Year</option>
        </select>
      </div>
    </div>

    <div class="m-t-sm" style="justify-content: space-between; display: flex">
      <button class="btn btn-primary" @click="goBack">
        <i style="margin-left: 0px; margin-right: 0px" class="material-icons">west</i>
      </button>
      <h3>{{ formattedDateRange }}</h3>
      <button :disabled="blockGoForward" class="btn btn-primary" @click="goForward">
        <i style="margin-left: 0px; margin-right: 0px" class="material-icons">east</i>
      </button>
    </div>

    <div class="m-t-sm">
      <button class="btn text-white float-right tx-uppercase tx-bold" @click="generateReport()"
        style="background-color: #1d6f42">
        Export Excel
      </button>
      <button class="btn btn-success float-right tx-uppercase tx-bold m-r-sm" @click="showAddQuestionModal = true">
        Add Question
      </button>
      <button class="btn btn-info float-right tx-uppercase tx-bold m-r-sm" @click="showSetTimeWindowModal = true">
        Set Time Window
      </button>
      <button class="btn btn-warning float-right tx-uppercase tx-bold m-r-sm" @click="showEditTimeWindowModal = true">
        Edit Time Window
      </button>
      <button class="btn btn-danger float-right tx-uppercase tx-bold m-r-sm" @click="showDeleteTimeWindowModal = true">
        Delete Time Window
      </button>
    </div><br>

    <h3>CHECKLIST: {{ checklist.name }}</h3>

    <div v-if="timeWindows.length">
      <h4>Existing Time Windows</h4>
      <ul>
        <li v-for="timeWindow in timeWindows" :key="timeWindow.id">
          ID: {{ timeWindow.id }} - {{ formatTimeWindow(timeWindow.start_time) }} - {{
            formatTimeWindow(timeWindow.end_time) }}
        </li>
      </ul>
    </div>

    <table class="table table-borderless">
      <thead>
        <tr>
          <th>Question</th>
          <th>Answer</th>
          <th>Date of Answer</th>
          <th>Edit Question</th>
          <th>Remove Question</th>
          <th>Published</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td colspan="7">
            <div class="text-center">
              <div class="spinner-border" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="tableData.length < 1">
          <td colspan="7" class="no-data-container">{{ $t("global.no_data") }}</td>
        </tr>
        <template v-for="(questions, index) in tableData" :key="'table-' + index">
          <template v-for="question in questions" :key="'question-' + question.id">
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <span 
                    v-if="hasFollowUps(question.id)" 
                    @click="toggleFollowUps(question.id)" 
                    class="me-2" 
                    style="cursor: pointer; min-width: 24px;"
                  >
                    <i class="material-icons">
                      {{ expandedQuestions[question.id] ? 'expand_less' : 'expand_more' }}
                    </i>
                  </span>
                  <span v-else class="me-2" style="min-width: 24px;"></span>
                  {{ question.question }}
                </div>
              </td>
              <td>
                <i v-if="parseInt(question.answer) === 1" class="material-icons text-success">done</i>
                <i v-else-if="parseInt(question.answer) === 0" class="material-icons text-danger">close</i>
                <span v-else>{{ question.answer || 'N/A' }}</span>
              </td>
              <td>{{ convertDate(question.answerDate) || 'N/A' }}</td>
              <td>
                <button class="btn btn-primary" @click="selectedQuestion = question.id; editingFollowUp = false;">
                  Edit
                </button>
                <div v-if="selectedQuestion === question.id && !editingFollowUp">
                  <input type="text" class="form-control m-t-sm" v-model="editedQuestion" />
                  <button class="btn btn-primary m-t-sm" @click="modifyQuestions(question.id)">
                    Save
                  </button>
                  <button class="btn btn-danger m-t-sm" @click="selectedQuestion = null">
                    Cancel
                  </button>
                </div>
              </td>
              <td>
                <button class="btn btn-danger" @click="removeQuestion(question.id)">
                  Remove
                </button>
              </td>
              <td>
                <i v-if="question.status === 1" class="material-icons text-success">done</i>
                <i v-else-if="question.status === 0" class="material-icons text-danger">close</i>
              </td>
              <td>
                <button class="btn btn-success" @click="publishQuestion(question.id)">
                  Publish
                </button>
                <button class="btn btn-secondary" @click="openAddFollowUpModal(question.id, question.question)">
                  Add Follow Up
                </button>
              </td>
            </tr>

            <template v-if="expandedQuestions[question.id] && followUpsByParentId[question.id]">
              <tr v-for="followUp in followUpsByParentId[question.id]" :key="'followup-' + followUp.id">
                <td>
                  <div class="ps-4">
                    <span class="me-2" style="color: #666;">↳</span>
                    {{ followUp.question }}
                    <span class="badge badge-info ms-2">
                      Shows when: {{ followUp.show_at === 1 ? 'Yes' : 'No' }}
                    </span>
                  </div>
                </td>
                <td>
                  <i v-if="parseInt(followUp.answer) === 1" class="material-icons text-success">done</i>
                  <i v-else-if="parseInt(followUp.answer) === 0" class="material-icons text-danger">close</i>
                  <span v-else>{{ followUp.answer || 'N/A' }}</span>
                </td>
                <td>{{ convertDate(followUp.answerDate) || 'N/A' }}</td>
                <td>
                  <button class="btn btn-sm btn-primary" @click="editFollowUp(followUp.id, followUp.question, followUp.show_at)">
                    Edit
                  </button>
                  <div v-if="selectedQuestion === followUp.id && editingFollowUp">
                    <input type="text" class="form-control m-t-sm" v-model="editedQuestion" />
                    <div class="form-group m-t-sm">
                      <label>Show when parent is answered:</label>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="editFollowUpShowAt" id="editShowWhenYes" value="1" v-model="editingFollowUpShowAt">
                        <label class="form-check-label" for="editShowWhenYes">Yes</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="editFollowUpShowAt" id="editShowWhenNo" value="0" v-model="editingFollowUpShowAt">
                        <label class="form-check-label" for="editShowWhenNo">No</label>
                      </div>
                    </div>
                    <button class="btn btn-primary m-t-sm" @click="modifyQuestions(followUp.id, true)">
                      Save
                    </button>
                    <button class="btn btn-danger m-t-sm" @click="selectedQuestion = null; editingFollowUp = false;">
                      Cancel
                    </button>
                  </div>
                </td>
                <td>
                  <button class="btn btn-sm btn-danger" @click="removeQuestion(followUp.id, true)">
                    Remove
                  </button>
                </td>
                <td>
                  <i v-if="followUp.status === 1" class="material-icons text-success">done</i>
                  <i v-else-if="followUp.status === 0" class="material-icons text-danger">close</i>
                </td>
                <td>
                  <button class="btn btn-sm btn-success" @click="publishQuestion(followUp.id, true)">
                    Publish
                  </button>
                </td>
              </tr>
            </template>
          </template>
        </template>
      </tbody>
    </table>

    <!-- Updated Add Question Modal -->
    <div v-if="showAddQuestionModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add New Question</h5>
            <button type="button" class="close" @click="showAddQuestionModal = false" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="newQuestion">Question <span class="text-danger">*</span></label>
              <input type="text" class="form-control" id="newQuestion" v-model="newQuestion.question" maxlength="300"
                required>
            </div>
            <div class="form-group">
              <label for="newDescription">Description</label>
              <textarea class="form-control" id="newDescription" v-model="newQuestion.description" maxlength="500"
                rows="3"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="clearNewQuestion">Clear</button>
            <button 
              type="button" 
              class="btn btn-primary" 
              @click="saveNewQuestion"
              :disabled="!newQuestion.question.trim() || !newQuestion.description.trim()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showAddQuestionModal" class="modal-backdrop fade show"></div>
    
    <div v-if="showAddFollowUpModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Follow Up Question</h5>
            <button type="button" class="close" @click="showAddFollowUpModal = false" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p><strong>Parent Question:</strong> {{ parentQuestionText }}</p>
            
            <div class="form-group">
              <label>Show this follow-up question when parent is answered: <span class="text-danger">*</span></label>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="followUpShowAt" id="showWhenYes" value="1" v-model="followUpShowAt">
                <label class="form-check-label" for="showWhenYes">Yes</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="followUpShowAt" id="showWhenNo" value="0" v-model="followUpShowAt">
                <label class="form-check-label" for="showWhenNo">No</label>
              </div>
            </div>
            
            <div class="form-group mt-3">
              <label for="followUpQuestion">Follow Up Question <span class="text-danger">*</span></label>
              <input 
                type="text" 
                class="form-control" 
                id="followUpQuestion" 
                v-model="newQuestion.question" 
                maxlength="300"
                required
              >
            </div>
            <div class="form-group">
              <label for="followUpDescription">Description</label>
              <textarea 
                class="form-control" 
                id="followUpDescription" 
                v-model="newQuestion.description" 
                maxlength="500"
                rows="3"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="clearNewQuestion">Clear</button>
            <button 
              type="button" 
              class="btn btn-primary" 
              @click="saveNewQuestion"
              :disabled="!newQuestion.question.trim() || followUpShowAt === null"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showAddFollowUpModal" class="modal-backdrop fade show"></div>

    <!-- Set Time Window Modal -->
    <div v-if="showSetTimeWindowModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Set Time Window</h5>
            <button type="button" class="close" @click="showSetTimeWindowModal = false" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="startTime">Start Time</label>
              <input type="time" class="form-control" id="startTime" v-model="startTime">
            </div>
            <div class="form-group">
              <label for="endTime">End Time</label>
              <input type="time" class="form-control" id="endTime" v-model="endTime">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="clearTimeWindow">Clear</button>
            <button type="button" class="btn btn-primary" :disabled="!startTime || !endTime" @click="saveTimeWindow">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showSetTimeWindowModal" class="modal-backdrop fade show"></div>

    <!-- Edit Time Window Modal -->
    <div v-if="showEditTimeWindowModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Time Window</h5>
            <button type="button" class="close" @click="showEditTimeWindowModal = false" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="editTimeWindowId">Select Time Window ID</label>
              <select v-model="selectedEditTimeWindowId" class="form-control" id="editTimeWindowId">
                <option v-for="timeWindow in timeWindows" :value="timeWindow.id" :key="timeWindow.id">
                  {{ timeWindow.id }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="editStartTime">Start Time</label>
              <input type="time" class="form-control" id="editStartTime" v-model="editStartTime">
            </div>
            <div class="form-group">
              <label for="editEndTime">End Time</label>
              <input type="time" class="form-control" id="editEndTime" v-model="editEndTime">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="clearEditTimeWindow">Clear</button>
            <button type="button" class="btn btn-primary" :disabled="!selectedEditTimeWindowId || !editStartTime || !editEndTime" @click="saveEditTimeWindow">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showEditTimeWindowModal" class="modal-backdrop fade show"></div>

    <!-- Delete Time Window Modal -->
    <div v-if="showDeleteTimeWindowModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Time Window</h5>
            <button type="button" class="close" @click="showDeleteTimeWindowModal = false" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="deleteTimeWindowId">Select Time Window ID</label>
              <select v-model="selectedDeleteTimeWindowId" class="form-control" id="deleteTimeWindowId">
                <option v-for="timeWindow in timeWindows" :value="timeWindow.id" :key="timeWindow.id">
                  {{ timeWindow.id }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showDeleteTimeWindowModal = false">Cancel</button>
            <button type="button" class="btn btn-danger" :disabled="!selectedDeleteTimeWindowId" @click="deleteTimewindow">Delete</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showDeleteTimeWindowModal" class="modal-backdrop fade show"></div>

  </div>
</template>

<script>
import axios from "axios";
import authHeader from "../../../../services/auth-header";
import { DateTime } from "luxon";
import * as XLSX from "xlsx";
import saveAs from "file-saver";

export default {
  name: "SiteChecklistQandA",
  props: {
    site: Object,
    checklist: Object,
  },
  data() {
    return {
      loading: true,
      questions: [],
      answers: [],
      tableData: [],
      date: DateTime.local().toFormat("yyyy-MM-dd"),
      selectedQuestion: null,
      editedQuestion: "",
      newQuestion: {
        question: '',
        description: ''
      },
      expandedQuestions: {}, 
      followUpShowAt: null,
      parentQuestionId: null,
      parentQuestionText: '',
      editingFollowUp: false,
      editingFollowUpShowAt: null,
      selectedTimeframe: "day",
      startDate: DateTime.local(),
      endDate: DateTime.local(),
      showAddQuestionModal: false,
      showAddFollowUpModal: false,
      showSetTimeWindowModal: false,
      showEditTimeWindowModal: false,
      showDeleteTimeWindowModal: false,
      startTime: "",
      endTime: "",
      editStartTime: "",
      editEndTime: "",
      timeWindows: [],
      selectedEditTimeWindowId: null,
      selectedDeleteTimeWindowId: null,
    };
  },
  mounted() {
    this.getQuestions();
    this.getTimeWindows();
  },
  methods: {
    getQuestions() {
      this.loading = true;
      axios
        .get(
          `https://api.varsanpr.com/api/checklist/${this.site.id}/checklist/${this.checklist.id}/questions`,
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          this.questions = response.data.questions;
          this.followUpQuestions = response.data.followUpQuestions || [];
          this.questions.forEach((question) => {
            question.answer = null;
            question.answerDate = null;
          });
          this.updateDateRange();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleFollowUps(questionId) {
      this.expandedQuestions = {
        ...this.expandedQuestions,
        [questionId]: !this.expandedQuestions[questionId]
      };
    },
    getTimeWindows() {
      axios
        .post(
          `https://api.varsanpr.com/api/checklist/time-windows/${this.checklist.id}`,
          { site_id: Number(this.site.id) },
          { headers: authHeader() }
        )
        .then((response) => {
          if (response.data.success) {
            this.timeWindows = response.data.timeWindows;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatTimeWindow(timeString) {
      return DateTime.fromISO(timeString).toFormat('HH:mm');
    },
    getAnswers() {
      this.loading = true;
      axios
        .get(
          `https://api.varsanpr.com/api/checklist/${this.site.id}/checklist/${this.checklist.id}/answers?&start_date=${this.startDate.toFormat("yyyy-MM-dd")}&end_date=${this.endDate.toFormat("yyyy-MM-dd")}`,
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          let answers = response.data.answers;
          this.tableData = this.groupAnswersByDate(answers);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    groupAnswersByDate(answers) {
      answers.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

      let groupedData = {};
      if (answers.length === 0) {
        // If there are no answers, group questions by default date
        groupedData[DateTime.local().toFormat('yyyy-MM-dd')] = JSON.parse(JSON.stringify(this.questions));
      } else {
        answers.forEach((answer) => {
          let date = answer.created_at.split('T')[0];
          if (!groupedData[date]) {
            groupedData[date] = JSON.parse(JSON.stringify(this.questions));
          }
          let question = groupedData[date].filter(
            (question) => parseInt(question.id) == parseInt(answer.question_id)
          )[0];
          question.answer = answer.answer;
          question.answerDate = answer.created_at;
        });
      }
      return Object.values(groupedData);
    },
    updateDateRange() {
      const now = DateTime.local();
      switch (this.selectedTimeframe) {
        case 'day':
          this.startDate = now.startOf('day');
          this.endDate = now.endOf('day');
          break;
        case 'week':
          this.startDate = now.startOf('week');
          this.endDate = now.endOf('week');
          break;
        case 'month':
          this.startDate = now.startOf('month');
          this.endDate = now.endOf('month');
          break;
        case 'year':
          this.startDate = now.startOf('year');
          this.endDate = now.endOf('year');
          break;
      }
      this.getAnswers();
    },
    convertDate(dateString) {
      if (!dateString) {
        return null;
      }
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', options);
    },
    // Add put and modify functions for questions, api work needed
    modifyQuestions(question_id, isFollowUp = false) {
      console.log("Modifying question: ", question_id);

      if (this.editedQuestion.trim() === "" || this.editedQuestion.length > 200) {
        alert("Please enter a valid question.");
        return;
      }

      const publishData = {
        site_id: Number(this.site.id),
        question: this.editedQuestion,
      };

      if (isFollowUp) {
        publishData.follow_up = true;
        if (this.editingFollowUpShowAt !== null) {
          publishData.follow_up_show_at = parseInt(this.editingFollowUpShowAt);
        }
      }

      axios
        .patch(
          `https://api.varsanpr.com/api/checklist/question/${question_id}`,
          publishData,
          { headers: authHeader() }
        )
        .then(() => {
          this.getQuestions();
          this.selectedQuestion = null;
          this.editedQuestion = "";
          this.editingFollowUpShowAt = null;
        })
        .catch((error) => {
          console.log(error);
          alert("Error updating question: " + (error.response?.data?.message || "Unknown error"));
        });
    },
    goBack() {
      switch (this.selectedTimeframe) {
        case 'day':
          this.startDate = this.startDate.minus({ days: 1 });
          this.endDate = this.startDate.endOf('day');
          break;
        case 'week':
          this.startDate = this.startDate.minus({ weeks: 1 });
          this.endDate = this.startDate.endOf('week');
          break;
        case 'month':
          this.startDate = this.startDate.minus({ months: 1 });
          this.endDate = this.startDate.endOf('month');
          break;
        case 'year':
          this.startDate = this.startDate.minus({ years: 1 });
          this.endDate = this.startDate.endOf('year');
          break;
      }
      this.getAnswers();
    },
    goForward() {
      if (this.blockGoForward) return;
      switch (this.selectedTimeframe) {
        case 'day':
          this.startDate = this.startDate.plus({ days: 1 });
          this.endDate = this.startDate.endOf('day');
          break;
        case 'week':
          this.startDate = this.startDate.plus({ weeks: 1 });
          this.endDate = this.startDate.endOf('week');
          break;
        case 'month':
          this.startDate = this.startDate.plus({ months: 1 });
          this.endDate = this.startDate.endOf('month');
          break;
        case 'year':
          this.startDate = this.startDate.plus({ years: 1 });
          this.endDate = this.startDate.endOf('year');
          break;
      }
      this.getAnswers();
    },
    formatDate(dateString) {
      if (!dateString) {
        return null;
      }
      return DateTime.fromISO(dateString).toFormat('yyyy LLL dd');
    },
    formatTime(dateString) {
      if (!dateString) {
        return null;
      }
      return DateTime.fromISO(dateString).toFormat('HH:mm:ss');
    },
    generateReport() {
      console.log("Exporting Excel");

      let QandA = [];
      let ans;
      for (let questions of this.tableData) {
        if (questions.length > 0) {
          QandA.push({
            Questions: `Date: ${this.formatDate(questions[0].answerDate)}`,
            Answers: '',
            "Time of Answer": ''
          });
        }
        for (let q of questions) {
          if (q.answer == 1) {
            ans = "✔";
          } else if (q.answer == "0") {
            ans = "❌";
          } else {
            ans = q.answer;
          }
          QandA.push({
            Questions: q.question,
            Answers: ans,
            "Time of Answer": this.formatTime(q.answerDate),
          });
        }
      }

      let book = XLSX.utils.book_new();
      let ws = XLSX.utils.json_to_sheet(QandA);
      XLSX.utils.book_append_sheet(book, ws, `${this.checklist.name} - Report`);
      let wopts = { bookType: "xlsx", bookSST: false, type: "array" };
      let wbout = XLSX.write(book, wopts);
      saveAs(
        new Blob([wbout], { type: "application/octet-stream" }),
        `${this.checklist.name} Report.xlsx`
      );
    },
    openAddFollowUpModal(questionId, questionText) {
      this.showAddFollowUpModal = true;
      this.parentQuestionId = questionId;
      this.parentQuestionText = questionText;
      this.followUpShowAt = "1"; 
      this.clearNewQuestion();
    },
    editFollowUp(followUpId, followUpQuestion, followUpShowAt) {
      this.selectedQuestion = followUpId;
      this.editedQuestion = followUpQuestion;
      this.editingFollowUp = true;
      this.editingFollowUpShowAt = followUpShowAt !== undefined ? String(followUpShowAt) : null;
    },
    clearNewQuestion() {
      this.newQuestion = { question: '', description: '' };
    },
    saveNewQuestion() {
      if (this.newQuestion.question.trim() === "" || this.newQuestion.question.length > 200) {
        alert("Please enter a valid question.");
        return;
      }

      const isFollowUp = this.showAddFollowUpModal;
      
      const questions = [{
        question: this.newQuestion.question,
        description: this.newQuestion.description || null
      }];

      const publishData = {
        site_id: Number(this.site.id),
        checklist_id: Number(this.checklist.id),
        questions: JSON.stringify(questions), 
      };

      if (isFollowUp) {
        publishData.question_id = this.parentQuestionId;
        publishData.follow_up = true;
        publishData.follow_up_show_at = parseInt(this.followUpShowAt);
      }

      axios
        .put(
          `https://api.varsanpr.com/api/checklist/new`,
          publishData,
          { headers: authHeader() }
        )
        .then(() => {
          this.getQuestions();
          
          // Reset form and close modal
          if (isFollowUp) {
            this.showAddFollowUpModal = false;
            this.parentQuestionId = null;
            this.parentQuestionText = '';
            this.followUpShowAt = null;
          } else {
            this.showAddQuestionModal = false;
          }
          
          this.clearNewQuestion();
        })
        .catch((error) => {
          console.log(error);
          alert("Error adding question: " + (error.response?.data?.message || "Unknown error"));
        });
    },
    publishQuestion(question_id, isFollowUp = false) {
      const publishData = {
        site_id: Number(this.site.id)
      };
      
      if (isFollowUp) {
        publishData.follow_up = true;
      }
      
      axios
        .patch(
          `https://api.varsanpr.com/api/checklist/question/${question_id}/status`,
          publishData,
          { headers: authHeader() }
        )
        .then(() => {
          this.getQuestions();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearTimeWindow() {
      this.startTime = "";
      this.endTime = "";
    },
    saveTimeWindow() {
      if (!this.startTime || !this.endTime) {
        alert("Please enter both start and end times.");
        return;
      }

      if (this.startTime >= this.endTime) {
        alert("Start time must be before end time.");
        return;
      }

      const timeWindowData = {
        site_id: Number(this.site.id),
        checklist_id: Number(this.checklist.id),
        start_time: this.startTime,
        end_time: this.endTime,
      };

      axios
        .put(
          `https://api.varsanpr.com/api/checklist/timewindow`,
          timeWindowData,
          { headers: authHeader() }
        )
        .then(() => {
          alert("Time window set successfully.");
          this.showSetTimeWindowModal = false;
          this.clearTimeWindow();
          this.getQuestions();
          this.getTimeWindows();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearEditTimeWindow() {
      this.editStartTime = "";
      this.editEndTime = "";
    },
    saveEditTimeWindow() {
      if (!this.editStartTime || !this.editEndTime) {
        alert("Please enter both start and end times.");
        return;
      }
      if (this.editEndTime <= this.editStartTime) {
        alert("End time cannot be before start time.");
        return;
      }

      const editTimeWindowData = {
        site_id: Number(this.site.id),
        start_time: this.editStartTime,
        end_time: this.editEndTime,
      };

      axios
        .patch(
          `https://api.varsanpr.com/api/checklist/timewindow/${this.selectedEditTimeWindowId}`,
          editTimeWindowData,
          { headers: authHeader() }
        )
        .then(() => {
          alert("Time window edited successfully.");
          this.showEditTimeWindowModal = false;
          this.getTimeWindows();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteTimewindow() {
      if (confirm("Are you sure you want to delete this time window?")) {
        axios
          .delete(
            `https://api.varsanpr.com/api/checklist/timewindow/${this.selectedDeleteTimeWindowId}`,
            {
              headers: authHeader(),
              data: {
                site_id: Number(this.site.id),
              },
            }
          )
          .then(() => {
            alert("Time window deleted successfully.");
            this.getQuestions();
            this.getTimeWindows();
          })
          .catch((error) => {
            console.error(error);
            alert("Failed to delete the Time window.");
          });
      }
    },
    removeQuestion(questionId, isFollowUp = false) {
      const deleteData = {
        site_id: Number(this.site.id)
      };
      
      if (isFollowUp) {
        deleteData.follow_up = true;
      }
      
      axios
        .delete(
          `https://api.varsanpr.com/api/checklist/question/${questionId}`,
          {
            headers: authHeader(),
            data: deleteData
          }
        )
        .then(() => {
          this.getQuestions();
        })
        .catch((error) => {
          console.log(error);
          alert("Error removing question: " + (error.response?.data?.message || "Unknown error"));
        });
    },
  },
  computed: {
    formattedDateRange() {
      if (this.selectedTimeframe === 'day') {
        return this.startDate.toFormat('yyyy-MM-dd');
      } else {
        return `${this.startDate.toFormat('yyyy-MM-dd')} - ${this.endDate.toFormat('yyyy-MM-dd')}`;
      }
    },
    blockGoForward() {
      return this.endDate >= DateTime.local().endOf(this.selectedTimeframe);
    },
    followUpsByParentId() {
      const followUpsMap = {};
      if (this.questions && this.questions.length > 0 && this.followUpQuestions && this.followUpQuestions.length > 0) {
        this.followUpQuestions.forEach(followUp => {
          if (!followUpsMap[followUp.question_id]) {
            followUpsMap[followUp.question_id] = [];
          }
          followUpsMap[followUp.question_id].push(followUp);
        });
      }
      return followUpsMap;
    },
    hasFollowUps() {
      return questionId => {
        return this.followUpsByParentId[questionId] && this.followUpsByParentId[questionId].length > 0;
      };
    }
  },
}
</script>